import React, { useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageFromOperator,
  createNoteInConversation,
  sendMessageOperator,
  sendWhatsappMessage,
} from "../../../../../slices/messages";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Compressor from "compressorjs";
import Autolinker from "autolinker";
import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js";
import { emitEvent } from "../../../../../socket/socket";
import {
  changeFile,
  changeNewMessage,
  dropFirstMessageFromOperator,
  // isShortcut,
} from "../../../../../slices/newMessage";
import { nanoid } from "nanoid";
import Suggestions from "./../../../../../components/SuggestionsField";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../../../../slices/notifier";
import { UploadImage } from "../../../../../slices/helpdesk";
import { openModal } from "../../../../../slices/modals";
import { parseHtml } from "../../../../../utilities/ParseHtmlToText";
import { embedVideoCallBack } from "../../../../../utilities/embedVideoCallback";
import {
  fetchOperators,
  getDepartments,
} from "../../../../../slices/operaters";
import { useTranslation } from "react-i18next";
import { isFound } from "../../../../../utilities/isFound";
import { departmentIcon } from "../../../../../assets";
import AudioRecorder from "../../../../../components/AudioRecorder";
import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";
import { clearHTMLTags } from "../../../../../utilities/clearHtmlTags";

const ChatBoxInput = ({
  activeFilterCardsMessagesButton,
  setActiveFilterCardsMessagesButton,
  user,
  newMessage,
  isshortcut,
  file,
  firstMessage,
}) => {
  const { t, i18n } = useTranslation();
  const { operators, departments } = useSelector((state) => state.operators);
  const [isTyping, setIsTyping] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {" "}
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const dispatch = useDispatch();
  const { clientConversationStored, conversationsStored } = useSelector(
    (state) => state.conversations
  );
  const { messagesStored } = useSelector((state) => state.messages);
  const textFieldRef = useRef();
  const [value, setValue] = useState("");
  const handleClick = (band) => {
    setValue(value + band);
    dispatch(changeNewMessage(value + band));
    textFieldRef.current?.focus();
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handlePasteImage = (evt) => {
    const blob = evt[0];
    let file = new File(
      [blob],
      `Copied image ${Date.now()}`,
      { type: "image/jpeg", lastModified: new Date().getTime() },
      "utf-8"
    );
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        dispatch(changeFile(compressedResult));
      },
    });
  };
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const htmlData = useMemo(() => {
    const rawContent = convertToRaw(editorState?.getCurrentContent());
    const html = draftToHtml(rawContent);
    const linkedHtml = Autolinker.link(html, { newWindow: true });
    return linkedHtml;
  }, [editorState, file]);
  const linkedHtml = Autolinker.link(newMessage, { newWindow: true });
  const contentBlock = htmlToDraft(linkedHtml || "");
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorStateContent = EditorState.createWithContent(contentState);
  const showModal = localStorage.getItem("showModal");
  const [enter, setEnter] = useState(false);

  useEffect(() => {
    let filledInput = false;
    let timer;
    convertToRaw(editorState.getCurrentContent()).blocks.forEach(
      (block) => block.text.trim() !== "" && (filledInput = true)
    );
    !isTyping &&
      filledInput &&
      !enter &&
      activeFilterCardsMessagesButton?.text === "Reply" &&
      (() => {
        emitEvent("IS_TYPING", {
          webSiteId: user?.websiteID,
          conversationId: clientConversationStored?._id,
          from: "OPERATOR",
          isTyping: true,
        });
        setIsTyping(true);
      })();
    filledInput &&
      (timer = setTimeout(function () {
        emitEvent("IS_TYPING", {
          webSiteId: user?.websiteID,
          conversationId: clientConversationStored?._id,
          from: "OPERATOR",
          isTyping: false,
        });
        setIsTyping(false);
      }, 20000));
    isTyping &&
      !filledInput &&
      !enter &&
      (timer = setTimeout(function () {
        emitEvent("IS_TYPING", {
          webSiteId: user?.websiteID,
          conversationId: clientConversationStored?._id,
          from: "OPERATOR",
          isTyping: false,
        });
        setIsTyping(false);
      }, 20000));

    return () => {
      clearTimeout(timer);
    };
  }, [htmlData, file]);

  useEffect(() => {
    !firstMessage && setEditorState(editorStateContent);
  }, [isshortcut]);

  const uploadImageCallBack = (file) => {
    // Define accepted file types
    const acceptedTypes = [".jpg", ".jpeg", ".png", ".svg", ".gif"];

    // Get the file extension
    const fileExtension = file.type.split("/").pop().toLowerCase();

    // Check if the file type is accepted
    if (!acceptedTypes.includes(`.${fileExtension}`)) {
      // Reject the Promise with an error message
      return Promise.reject("Invalid file type");
    }
    // If the file type is accepted, proceed with the upload
    return new Promise((resolve, reject) => {
      dispatch(
        UploadImage({
          websiteID: user.websiteID,
          file,
        })
      )
        .then((res) => {
          resolve({ data: { link: res.payload } });
        })
        .catch((error) => {
          // Handle the error (e.g., show an error message)
          reject("Image upload failed");
        });
    });
  };

  const onEditorStateChange = (val) => {
    let rawContent = convertToRaw(val?.getCurrentContent());
    rawContent?.blocks.find((block) => {
      const firstIndexOfSearchableCharacter = block?.text?.indexOf("@");
      let index = -1;
      let count = 0;
      while ((index = block?.text?.indexOf("@", index + 1)) >= 0) {
        if (index > 0 && block?.text[index - 1] === " ") {
          count++;
        }
      }
      return (
        (count > 0 || firstIndexOfSearchableCharacter === 0) &&
        setActiveFilterCardsMessagesButton({ id: 2, text: "Note" })
      );
    });

    const html = draftToHtml(rawContent);
    const linkedHtml = Autolinker.link(html, { newWindow: true });
    dispatch(changeNewMessage(linkedHtml));
    const currentContentTextLength = editorState
      .getCurrentContent()
      .getPlainText().length;
    const newContentTextLength = val.getCurrentContent().getPlainText().length;
    if (currentContentTextLength === 0 && newContentTextLength === 1) {
      // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
      setEditorState(EditorState.moveFocusToEnd(val));
    } else {
      setEditorState(val);
    }
  };
  const entityMap = convertToRaw(contentState).entityMap;
  const mentions = [];
  Object.values(entityMap).forEach((entity) => {
    if (entity.type === "MENTION") {
      mentions.push({
        type: entity.data.url.split("/")[5],
        _id: entity.data.url.split("/").pop(),
      });
    }
  });
  useEffect(() => {
    operators?.length === 0 &&
      user.websiteID &&
      !firstMessage &&
      dispatch(fetchOperators({ websiteID: user.websiteID, query: "" }));
    departments?.length === 0 &&
      user.websiteID &&
      !firstMessage &&
      dispatch(getDepartments({ websiteID: user?.websiteID, page: 1 }));
  }, [
    open,
    operators?.length === 0,
    departments?.length === 0,
    user.websiteID,
    activeFilterCardsMessagesButton.text === "Note",
  ]);

  const mentionOperators = operators
    ?.filter(
      (operator) => operator?.isVerified === true && operator?._id !== user?._id
    )
    ?.map((item) => {
      return {
        text: (
          <div className="mention-operators-container">
            {" "}
            <Avatar
              src={item?.avatar}
              alt="avatar"
              className="mention-operator-avatar"
            />{" "}
            {item.firstName + " " + item.lastName}
          </div>
        ),
        value: item.firstName + " " + item.lastName,
        url:
          "operator" +
          "/" +
          item.firstName +
          " " +
          item.lastName +
          "/" +
          item._id,
      };
    })
    ?.concat(
      departments?.map((item) => {
        return {
          text: (
            <div className="mention-operators-container">
              {" "}
              <Avatar
                src={departmentIcon}
                alt="avatar"
                className="mention-operator-avatar"
              />{" "}
              {item.name}
            </div>
          ),
          value: item.name,
          url: "department" + "/" + item.name + "/" + item._id,
        };
      })
    );

  const onEnterClick = () => {
    if (
      !(
        convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
        convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
      ) ||
      file
    ) {
      const _id = nanoid();
      setEditorState(EditorState.createEmpty());
      if (firstMessage) {
        messagesStored?.length === 0
          ? dispatch(
              dropFirstMessageFromOperator({
                clientID: firstMessage?.id,
                client: firstMessage,
                websiteID: user?.websiteID,
                content: parseHtml(newMessage),
                file,
                user,
                conversationsStored,
              })
            )
          : (() => {
              dispatch(
                addMessageFromOperator({
                  content: parseHtml(newMessage),
                  createdBy: user,
                  from: "OPERATOR",
                  file: file ? URL.createObjectURL(file) : null,
                  filetype: file ? file?.type : null,
                  fileObject: file ? file : null,
                  _id,
                  conversation: messagesStored[0]?.conversation,
                  createdBy: user,
                })
              );
              isTyping &&
                (() => {
                  emitEvent("IS_TYPING", {
                    // website is just for testing
                    webSiteId: user?.websiteID,
                    conversationId: messagesStored[0]?.conversation,
                    from: "OPERATOR",
                    isTyping: false,
                  });
                  setIsTyping(false);
                })();

              dispatch(
                sendMessageOperator({
                  content: parseHtml(newMessage),
                  conversation: messagesStored[0]?.conversation,
                  websiteId: user?.websiteID,
                  from: "OPERATOR",
                  file,
                  filetype: file ? file?.type : null,
                  _id,
                  firstName: user?.firstName,
                  availability: { status: user?.availability?.status },
                  createdBy: user,
                  createdAt: new Date(Date.now()),
                  user,
                  not_assigned:
                    typeof clientConversationStored?.assigned?.user !== "object"
                      ? true
                      : false,
                  clientConversationStored,
                  isFound: isFound(
                    conversationsStored,
                    messagesStored[0]?.conversation
                  ),
                  meta: firstMessage,
                  firstMessage: true,
                })
              ).then((res) => {
                if (!res?.error) {
                  setEditorState(EditorState.createEmpty());
                  dispatch(changeNewMessage(""));
                  dispatch(changeFile());
                  setEnter(false);
                } else {
                  showSnackbar({
                    variant: "error",
                    message: res?.error?.message,
                  });
                }
              });
            })();
      } else {
        if (activeFilterCardsMessagesButton.text === "Note") {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = parseHtml(newMessage);
          const aTags = tempElement.querySelectorAll("a[data-mention]");
          aTags.forEach((aTag) => {
            const replacedATag = document.createElement("a");
            replacedATag.textContent = "@" + aTag.dataset.value;
            aTag.replaceWith(replacedATag);
          });
          dispatch(
            addMessageFromOperator({
              content: tempElement?.innerHTML,
              createdBy: user,
              // from: "OPERATOR",
              file: file ? URL.createObjectURL(file) : null,
              filetype: file ? file?.type : null,
              fileObject: file ? file : null,
              _id,
              conversation: clientConversationStored?._id,
              isNote: true,
              createdAt: new Date(Date.now()),
            })
          );
          dispatch(changeNewMessage(""));
          dispatch(changeFile());
          dispatch(
            createNoteInConversation({
              content: tempElement?.innerHTML,
              conversation: clientConversationStored?._id,
              websiteId: user?.websiteID,
              mentionedUsers: mentions
                ?.filter((item) => item?.type === "operator")
                .map((item) => item?._id),
              departments: mentions
                ?.filter((item) => item?.type === "department")
                .map((item) => item?._id),
              assignedDepartments: clientConversationStored?.departments,
              _id,
            })
          ).then((res) => {
            if (!res?.error) {
              setEditorState(EditorState.createEmpty());
              dispatch(changeNewMessage(""));
              dispatch(changeFile());
            } else {
              showSnackbar({
                variant: "error",
                message: res?.error?.message,
              });
            }
          });
        } else {
          dispatch(
            addMessageFromOperator({
              content: parseHtml(newMessage),
              createdBy: user,
              from: "OPERATOR",
              file: file ? URL.createObjectURL(file) : null,
              filetype: file ? file?.type : null,
              fileObject: file ? file : null,
              _id,
              conversation: clientConversationStored?._id,
            })
          );
          dispatch(changeNewMessage(""));
          dispatch(changeFile());
          isTyping &&
            (!clientConversationStored?.source ||
              clientConversationStored?.source === "web") &&
            // if we're typing and the message from whatsapp, it shouldn't work
            (() => {
              emitEvent("IS_TYPING", {
                // website is just for testing
                webSiteId: user?.websiteID,
                conversationId: clientConversationStored?._id,
                from: "OPERATOR",
                isTyping: false,
              });
              setIsTyping(false);
            })();
          dispatch(
            !clientConversationStored?.source ||
              ["web", "bot"].includes(clientConversationStored?.source) // this attribute to test in thunk that the message is from Whatsapp or not
              ? sendMessageOperator({
                  content: parseHtml(newMessage),
                  conversation: clientConversationStored?._id,
                  websiteId: user?.websiteID,
                  from: "OPERATOR",
                  file,
                  filetype: file ? file?.type : null,
                  _id,
                  firstName: user?.firstName,
                  availability: { status: user?.availability?.status },
                  createdBy: user,
                  createdAt: new Date(Date.now()),
                  user,
                  not_assigned:
                    typeof clientConversationStored?.assigned?.user !== "object"
                      ? true
                      : false,
                  clientConversationStored,
                  isFound: isFound(
                    conversationsStored,
                    clientConversationStored?._id
                  ),
                  meta: clientConversationStored?.meta,
                })
              : sendWhatsappMessage({
                  content: clearHTMLTags(newMessage),
                  conversation: clientConversationStored?._id,
                  websiteId: user?.websiteID,
                  from: "OPERATOR",
                  file,
                  filetype: file ? file?.type : null,
                  _id,
                  firstName: user?.firstName,
                  availability: { status: user?.availability?.status },
                  createdBy: user,
                  createdAt: new Date(Date.now()),
                  user,
                  not_assigned:
                    typeof clientConversationStored?.assigned?.user !== "object"
                      ? true
                      : false,
                  clientConversationStored,
                  isFound: isFound(
                    conversationsStored,
                    clientConversationStored?._id
                  ),
                  meta: clientConversationStored?.meta,
                }) // TODO: wanna add the api related to whatsapp messages
          ).then((res) => {
            if (!res?.error) {
              setEditorState(EditorState.createEmpty());
              dispatch(changeNewMessage(""));
              dispatch(changeFile());
              setEnter(false);
            } else {
              showSnackbar({
                variant: "error",
                message: res?.error?.message,
              });
            }
          });
        }
      }
    }
  };
  const handleReturn = (e) => {
    // Check if the user pressed Shift + Enter
    e.preventDefault();
    if (e.shiftKey) {
      // If Shift + Enter was pressed, create a new line
      const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "\n"
      );
      setEditorState(
        EditorState.push(editorState, newContentState, "insert-characters")
      );
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const startKey = selection.getStartKey();
      const currentBlock = contentState.getBlockForKey(startKey);
      if (currentBlock) {
        const blockData = currentBlock.getData();
        if (blockData) {
          const newContentState = Modifier.splitBlock(contentState, selection);
          const splitState = EditorState.push(
            editorState,
            newContentState,
            "split-block"
          );
          const splitStateWithData = Modifier.mergeBlockData(
            splitState.getCurrentContent(),
            splitState.getSelection(),
            blockData
          );
          const finalState = EditorState.push(
            editorState,
            splitStateWithData,
            "split-block"
          );
          onEditorStateChange(finalState);
          return true;
        }
      }
      return "handled";
    }
    // If only Enter was pressed, submit the form
    if (
      showModal === "true" &&
      e.code === "Enter" &&
      (htmlData.replace(/<p>(\s|&nbsp;)*<\/p>/g, "").trim() !== "" || file)
    ) {
      onEnterClick();
      setEnter(true);
      return "handled";
    } else {
      if (
        (htmlData.replace(/<p>(\s|&nbsp;)*<\/p>/g, "").trim() !== "" || file) &&
        e.code === "Enter"
      ) {
        dispatch(
          openModal("confirm-send", {
            onEnterClick: onEnterClick,
            setEditorState: setEditorState,
          })
        );
        setEnter(true);
        return "handled";
      }
      return "handled";
    }
  };
  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file?.type?.includes("image") && !file?.type?.includes("gif")) {
      new Compressor(file, {
        quality: 0.8,
        success: (compressedResult) => {
          compressedResult?.size <= 5 * 1024 * 1024
            ? dispatch(changeFile(compressedResult))
            : showSnackbar({
                variant: "warning",
                message: t("Please try to upload file with 5MB size or less"),
              });
        },
      });
    } else {
      file?.size <= 5 * 1024 * 1024
        ? dispatch(changeFile(file))
        : showSnackbar({
            variant: "warning",
            message: t("Please try to upload file with 5MB size or less"),
          });
    }
  };
  let inputRef;
  function handleBeforeInput(event) {
    if (event.inputType === "insertLineBreak") {
      event.preventDefault();
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];

    if (file) {
      dispatch(changeFile(file));
    }
  }
  const audioRecorder = useAudioRecorder();

  const placeholder =
    audioRecorder?.status !== "idle"
      ? `${new Date(audioRecorder?.timer * 1000).toISOString().substr(11, 8)}`
      : `${t("Send Your Message")}...`;

  return (
    <div
      className={
        activeFilterCardsMessagesButton.text === "Note"
          ? "chat-box-container yellow"
          : "chat-box-container"
      }
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
      onDrop={handleDrop}
    >
      {" "}
      {activeFilterCardsMessagesButton.text === "Shortcuts" && (
        <div className="shortcut-box">
          {" "}
          <span>{t("Shortcuts")}</span>{" "}
        </div>
      )}
      {activeFilterCardsMessagesButton.text === "Note" && (
        <div className="note-box">
          {" "}
          <span>{t("Note")}</span>{" "}
        </div>
      )}
      <Editor
        editorState={editorState}
        handleReturn={handleReturn}
        handlePastedFiles={
          activeFilterCardsMessagesButton.text === "Note"
            ? ""
            : handlePasteImage
        }
        onBeforeInput={handleBeforeInput}
        placeholder={placeholder}
        mention={{
          separator: " ",
          trigger: "@",
          entityMutability: "IMMUTABLE",
          suggestions: mentionOperators,
        }}
        hashtag={{}}
        toolbarStyle={{
          marginLeft:
            activeFilterCardsMessagesButton?.text === "Note"
              ? "-60px"
              : activeFilterCardsMessagesButton?.text === "Shortcuts"
              ? "-102px"
              : 0,
        }}
        toolbar={{
          options: [
            // "embedded",
            "emoji",
            "image",
            //  "link",
            "textAlign",
            "inline",
          ],
          embedded: {
            embedCallback: embedVideoCallBack,
          },
          inline: { inDropdown: false },
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            defaultSize: {
              height: "100px",
              width: "100px",
            },
            inputAccept: ".jpg, .png, .jpeg, .svg, .gif", // Specify accepted file extensions here
          },
          textAlign: { inDropdown: false },
          blockType: { inDropdown: false },
          link: { inDropdown: false },
        }}
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        editorStyle={{
          direction: i18n.language.includes("ar") ? "rtl" : "ltr",
        }}
      />{" "}
      <Suggestions
        handleClick={handleClick}
        handlePopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
        open={open}
      />{" "}
      <div style={{ alignSelf: "flex-start" }}>
        {" "}
        <div
          className={
            activeFilterCardsMessagesButton.text === "Note"
              ? "chat-box-icons-container "
              : "chat-box-icons-container"
          }
        >
          {" "}
          <div className="actions-wrapper">
            {" "}
            {i18n.language === "ar" ? (
              <>
                <button
                  className={
                    activeFilterCardsMessagesButton.text === "Note"
                      ? "send-message disabled"
                      : "send-message"
                  }
                  disabled={
                    activeFilterCardsMessagesButton.text === "Note"
                      ? true
                      : false
                  }
                  onClick={() => {
                    inputRef.click();
                  }}
                >
                  {" "}
                  <AddIcon
                    fontSize="small"
                    style={{
                      color:
                        activeFilterCardsMessagesButton.text === "Note"
                          ? "gray"
                          : "",
                      margin: "2px",
                    }}
                  />{" "}
                </button>{" "}
                {/* <AudioRecorder audioRecorder={audioRecorder} /> */}
                <button className="send-message" onClick={onEnterClick}>
                  {" "}
                  <SendIcon
                    fontSize="small"
                    style={{ marginLeft: "2px" }}
                  />{" "}
                </button>{" "}
              </>
            ) : (
              <>
                <button className="send-message" onClick={onEnterClick}>
                  {" "}
                  <SendIcon
                    fontSize="small"
                    style={{ marginLeft: "2px" }}
                  />{" "}
                </button>{" "}
                {/* <AudioRecorder audioRecorder={audioRecorder} /> */}
                <button
                  className={
                    activeFilterCardsMessagesButton.text === "Note"
                      ? "send-message disabled"
                      : "send-message"
                  }
                  disabled={
                    activeFilterCardsMessagesButton.text === "Note"
                      ? true
                      : false
                  }
                  onClick={() => {
                    inputRef.click();
                  }}
                >
                  {" "}
                  <AddIcon
                    fontSize="small"
                    style={{
                      color:
                        activeFilterCardsMessagesButton.text === "Note"
                          ? "gray"
                          : "",
                      margin: "2px",
                    }}
                  />{" "}
                </button>
              </>
            )}
            <input
              type="file"
              name="file"
              id="upload-file"
              ref={(refParam) => (inputRef = refParam)}
              accept=".jpg, .png, .jpeg, .svg, .gif, .pdf, .doc, .docx, .txt, .wmv, .mp4, .mov, .mp3"
              onChange={handleUploadFile}
            />{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};
export default ChatBoxInput;
